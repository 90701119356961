<template>
    <page>
        <page-head>
            <!-- <app-button property="success" icon="icon-add" @click="createModal">
                {{ translate('button.Add') }}
            </app-button> -->
        </page-head>

        <page-body>
            <data-grid
                :data-source="offers"
                :columns="columns"
                :action-column-width="120"
                :action-column-text="translate(translateKey + '.Label.Action')"
            >
                <div slot="actionSlot" slot-scope="list">
                    <app-button
                        v-if="can(permission + '.update')"
                        @click="createModal(list.row.form)"
                        :sm="true"
                        :title="translate('button.Info')"
                        icon="icon-info"
                        property="success"
                    />
                    

                </div>
            </data-grid>
        </page-body>

        <modal :id="modalId" size="sm">
            <modal-head>
                <modal-title>{{ currentPage.title }}</modal-title>
            </modal-head>
            <modal-body v-if="modelShow">
                <form @submit.prevent="save">
                    <grid>

                        <form-group :label="'İstifadəçinin adı'" name="name">
                            <form-text v-model="form.name"/>
                        </form-group>

                        <form-group :label="'Əlaqə nömrəsi'" name="number">
                            <form-number v-model="form.number"/>
                        </form-group>

                        <form-group :label="'Text'" name="text">
                            <form-html-editor v-model="form.text"/>
                        </form-group>

                        <form-group :label="'Tarix'" :name="'created_at'">
                            <form-text v-model="form.created_at" />
                        </form-group>
                    </grid>
                </form>
            </modal-body>
        </modal>
    </page>
</template>

<script>
/*
 * Import Components
 * */
import {mapActions, mapState} from 'vuex';

const modalId = 'createModal';
const translateKey = 'crm.HiddenMessage';

export default {
    name: "OfferIndex",
    data() {
        return {
            translateKey,
            modalId,
            modelShow: false,
            columns: [
                {
                    caption: translateKey + '.Label.Name',
                    dataField: 'name',
                    show: true
                },
                {
                    caption: translateKey + '.Label.Phone',
                    dataField: 'number',
                    show: true
                },
                {
                    caption: translateKey + '.Label.Created',
                    dataField: 'created_at',
                    show: true
                },
            ],
            form: {}
        }
    },
    computed: {
        ...mapState('OfferStore', ['offers']),

        permission() {
            return this.currentPage.permission;
        }
    },
    methods: {
        ...mapActions('OfferStore', ['getOffers', 'setOffer', 'actionOffer', 'deleteOffer']),
        /*
         * Form Create
         * */
        formCreate(item = {}) {
            const form = {
                id: item.id || null,
                name: item.name || null,
                number: item.number || null,
                text: item.text || null,
                created_at: item.created_at || null,
            }
            
            this.form = form;
            this.modelShow = true;
        },
        /*
         * Create Modal
         * */
        createModal(item = {}) {
            this.modal(this.modalId)
            this.modelShow = false;
            this.resetError();
            this.formCreate(item);
        },
        /*
         * Remove
         * */
        remove(id) {
            this.alert().then(r => this.deleteOffer(id).then(r => this.getOffers()))
        },
        /*
         * Action
         * */
        action(item, type) {
            let action = item.action[type] ? 0 : 1;
            this.actionOffer({id: item.id, type, action}).then(r => this.getOffers())
        },
        /*
         * Save
         * */
        save() {
            this.setOffer(this.form)
            .then(r => {
                if (r) {
                    this.modal(this.modalId);
                    this.getOffers();
                }
            })
        }
    }, 
    created() {
        this.getOffers();
    }
}
</script>

<style scoped>

</style>
